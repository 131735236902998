import React, { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Form, Button, Alert, OverlayTrigger, Tooltip, Card} from 'react-bootstrap';
//@ts-ignore
/* import COSEBilkent from 'cytoscape-cose-bilkent'; */
//@ts-ignore
import cytoscape from "cytoscape";
import * as Maps from '../modules/map'
//@ts-ignore
import LoadingOverlay from 'react-loading-overlay-ts';

import Select, { OnChangeValue } from 'react-select';

import { buildSingleNodeSelector, buildNodeSelector } from '../modules/map_utils'
import Graph from './Graph'
import ProgressUI from './ProgressUI'


import prettyMilliseconds from 'pretty-ms';

// Refs
// https://codesandbox.io/p/sandbox/aged-dream-3yn2dm?file=%2Fsrc%2Fworker.ts&selection=%5B%7B%22endColumn%22%3A1%2C%22endLineNumber%22%3A14%2C%22startColumn%22%3A1%2C%22startLineNumber%22%3A14%7D%5D
// https://stackoverflow.com/questions/68923765/work-with-web-workers-using-comlink-on-a-webpack5-project-with-typescript
import * as Comlink from 'comlink'
import { SolverWorkerAPI, ProgressUpdate, SolveResult } from '../modules/solver.worker'
import { MapLoaderWorkerAPI } from '../modules/maploader.worker';

// Initialize workers
const worker: Worker = new Worker(new URL("../modules/solver.worker.ts", import.meta.url));
const solveWorker  = Comlink.wrap<SolverWorkerAPI>(worker)
const worker2: Worker = new Worker(new URL("../modules/maploader.worker.ts", import.meta.url));
const mapLoaderWorker  = Comlink.wrap<MapLoaderWorkerAPI>(worker2)

const Footer = () => (
  <div className="footer" style={{
      position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#bbb",
        opacity: 0.8,
        color: "black",
        textAlign: "center"
  }}>
        <div>Made by  <a href="https://sriramsami.com/" target="_blank">Sriram Sami</a> and <a href="https://www.linkedin.com/in/chun-yen-kok-830ab0137/?originalSubdomain=sg" target="_blank">Kok Chun Yen</a></div>
  </div>
);



const App: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loadMapWithRooms, setLoapMapWithRooms] = useState<boolean>(false);

    // Map
    const [fullMap, setFullMap] = useState<any[]>([]);
    const [map, setMap] = useState<any[]>([]);

    // Locations
    const [inputWards, setInputWards] = useState<string[]>([])
    /* const [inputWards, setInputWards] = useState<string[]>(["W45-7", "W55-7", "W47-1", "W64-15", "W74-25 (6-11)", "W78-3", "W67-6", "W73-14", "W63-20", "W55-32", "W47-22 (7-12)", "W65-12"]); */
    const [startingLocation, setStartingLocation] = useState("");
    const [endingLocation, setEndingLocation] = useState("");

    // Path options
    const [allowStairs, setAllowStairs] = useState<boolean>(false);

    // Solver options
    type SolveOptionValue = "exact" | "genetic";
    type SolverOption = {value: SolveOptionValue, label: string};
    const solverOptions: SolverOption[] = [{value: "exact", label: "Exact Solver: Very fast for short routes, very slow for long routes (e.g., > 10 locations to visit), but always finds best route"}, {value: "genetic", label: "Genetic Solver: Faster for long routes, often finds best route"}]
    const [selectedSolver, setSelectedSolver] = useState<SolverOption>(solverOptions[0]);

    // Path results
    const [route, setRoute] = useState<string[]>([]);
    const [bestFullPath, setBestFullPath] = useState<string[][]>([]);
    const [bestCost, setBestCost] = useState<number>(0);

    // Validation / runtime states
    const [routeErrors, setRouteErrors] = useState<string[]>([]);
    const [uniqueError, setUniqueError] = useState<boolean>(false);
    const [solveStrategy, setSolveStrategy] = useState<"exact" | "genetic">("exact");
    const [isSearchingForPath, setIsSearchingForPath] = useState<boolean>(false);

    // Runtime/progress statistics
    const [executionTime, setExecutionTime] = useState<number>(0);
    const [progressPercent, setProgressPercent] = useState<number>(0.0);
    const [etaMs, setEtaMs] = useState<number>(0.0);

    // Create webworker solver
    const [cyInstance, setCyInstance] = useState<cytoscape.Core | null>(null);

    // Initial load of map
    useEffect(() => {
        setIsLoading(true);
        const loadMap = async () => {
            const map = await mapLoaderWorker.loadSGHMap(loadMapWithRooms);
            setFullMap(map)
            changeMapFiltering(map, allowStairs);
            setIsLoading(false);
        };
        loadMap();
        console.log("I fire when loadMapWithRooms changes.")
    },[loadMapWithRooms]);


    const changeMapFiltering = ((currentMap: any, allowStairs: boolean) => {
        if (!allowStairs) {
            console.log("removing stairs")
            setMap(currentMap.filter((el: any) => {
                return !el.data["source"] || (el.data["isStair"] === false)
            }));
        } else {
            console.log("Setting full map")
            setMap(currentMap)
        }
    })

    const RenderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Please fill in the start location, end location, and wards to visit before finding the best route.
        </Tooltip>
    );

    const clearAllNotificationState = () => {
        setRouteErrors([])
        setUniqueError(false);
        setRoute([])
        setProgressPercent(0.0);
    }

    const parseWards = (inputWards: string[]) => inputWards.map((ward) => ward.trim()).filter((ward) => ward !== "");

    const handleSubmitFindPath = async (solveStrategy: "exact" | "genetic") => {
        setSolveStrategy(solveStrategy);
        clearAllNotificationState();

        console.log("waiting for worker")
        /* await worker(); */
        console.log("Done")
        const start = performance.now();

        let allWards = [startingLocation]
        const wards = parseWards(inputWards);
        allWards = allWards.concat(wards, [endingLocation])


        if (cyInstance === null) {
            alert("Graph not instantiated!")
            return;
        }

        // Validation
        console.log("Awaiting async validation from webworker...")
        const result = await solveWorker.loadAndValidateGraph(cyInstance.elements().jsons(), allWards)
        /* const result = await validate(cyInstance.elements().jsons(), allWards) */
        if (!result.ok) {
            if (result.error.error === "missing") {
                clearAllNotificationState()
                //@ts-ignore
                setRouteErrors(result.error.details.map(v => v.nodeId))
            } else if (result.error.error === "unique") {
                clearAllNotificationState()
                setUniqueError(true);
            }
            return
        } else {
            setRouteErrors([])
            setUniqueError(false);
        }

        // Clear the route - this works because we have an await-point below, so react can propagate the updates
        setRoute([]);
        setIsSearchingForPath(true)

        // Solving
        console.log("Starting path solve")
        /* const shortestPath = await solveWorker.solve(cyInstance.elements().jsons(), allWards) */

        let solveResult = null;
        const progressUpdateFx = Comlink.proxy((progress: ProgressUpdate) => {
            setProgressPercent(parseFloat((progress.progressRatio * 100).toFixed(2)))
            setEtaMs(progress.eta_ms)
        });

        if (solveStrategy === "exact")  {
            solveResult = await solveWorker.solve(allWards, progressUpdateFx)
        } else if (solveStrategy === "genetic") {
            solveResult = await solveWorker.solveGenetic(allWards, progressUpdateFx);
        } else {
            throw new Error("Invalid solve strategy")
        }

        setIsSearchingForPath(false)
        if (solveResult.ok) {
            const { path: bestPath, fullPath: bestFullPath, cost: bestCost } = solveResult.value;
            setRoute(bestPath);
            setBestFullPath(bestFullPath);
            setBestCost(bestCost);
        }

        console.log("Done!")
        const end = performance.now();
        console.log(`Execution time: ${end - start} ms`);
        setExecutionTime(parseFloat((end-start).toFixed(2)))
    };

    const abortSearch = async () => {
        console.log("Button aborting!")
        solveWorker.abortSolve();
    }

    const disableButtons = !startingLocation || !endingLocation //|| inputWards.length === 0

    type OptionType = {
        value: string;
        label: string;
    };

    const graphOptions: OptionType[] = map.filter((element) => !(element.data["source"])).map((element) => ({
        value: element.data.id,
        label: element.data.id,
    }));

    const mapVariantOptions: OptionType[] = [
        { value: "no-rooms", label: "SGH Wards (smaller map)" },
        { value: "with-rooms", label: "Full SGH Map (includes all relevant wards & areas)" },
    ]


    const filterOptions = (currentValue: string, otherValues: string[]): OptionType[] => {
        return graphOptions.filter((option) => {
            return option.value !== currentValue && !otherValues.includes(option.value);
        });
    };



    return (
        <LoadingOverlay active={isLoading} spinner text='Loading map...' fadeSpeed={1000}>
            <Container className="my-5 main-container">
                <Row className="justify-content-center">
                    <Col>
                        <h1 className="text-center mb-1">Round Trip, MD</h1>
        <h2 className="text-center mb-4"> (a route planner for rounds within SGH) </h2>
                        <LoadingOverlay
                            active={isSearchingForPath}
                            spinner
                            text='Searching for best route...'
                        >
                            <div style={{padding: "10px"}}>
                                <Form>

                                <Card>
                                    <Card.Header> <strong> Setup: Select Map </strong> </Card.Header>
                                    <Card.Body>

                                        <Form.Group controlId="wards">
                                        <Form.Label>Choose which version of the SGH map you want to use:</Form.Label>
                                        <Select
                                            options={mapVariantOptions}
                                            placeholder="Type to search"
                                            value={loadMapWithRooms ? mapVariantOptions[1] : mapVariantOptions[0]}
                                               onChange={(selectedOption: OnChangeValue<OptionType, false>) => {
                                                   setLoapMapWithRooms((selectedOption as OptionType).value === "with-rooms")
                                               }}
                                        />
                                    </Form.Group>

                                    </Card.Body>
                                </Card>

                                <div style={{"padding": "10px"}}/>


                                <Card>
                                    <Card.Header> <strong> Step 1: Select Route (Start, End, Places to visit) </strong> </Card.Header>
                                    <Card.Body>
                                    <Row className="mb-3">
                                        <Form.Group  as={Col} controlId="startingLocation" className="mb-3">
                                            <Form.Label>Starting Location:</Form.Label>
                                            <Select
                                                options={filterOptions(startingLocation, [...inputWards])}
                                                placeholder="Type to search"
                                                value={startingLocation ? { value: startingLocation, label: startingLocation } : null}
                                                onChange={(selectedOption: OnChangeValue<OptionType, false>) => {
                                                    setStartingLocation(selectedOption ? (selectedOption as OptionType).value : '')
                                                }}/>
                                        </Form.Group>
                                        <Form.Group  as={Col} controlId="endingLocation" className="mb-3">
                                            <Form.Label>Ending Location:</Form.Label>
                                            <Select
                                                options={filterOptions(endingLocation, [...inputWards])}
                                                placeholder="Type to search"
                                                value={endingLocation ? { value: endingLocation, label: endingLocation } : null}
                                                onChange={(selectedOption: OnChangeValue<OptionType, false>) => {
                                                    setEndingLocation(selectedOption ? (selectedOption as OptionType).value : '')
                                                }}/>
                                        </Form.Group>
                                    </Row>
                                    <Form.Group controlId="wards">
                                        <Form.Label>Select the locations you have to visit:</Form.Label>
                                        <Select
                                            options={filterOptions('', [startingLocation, endingLocation])}
                                            isMulti
                                            placeholder="Type to search"
                                            value={inputWards ? inputWards.map((ward) => ({ value: ward, label: ward })) : []}
                                            onChange={(selectedOptions: OnChangeValue<OptionType, true>) =>
                                                {
                                                    const selectedWards = selectedOptions ? (selectedOptions as OptionType[]).map((option) => option.value) : []
                                                    setInputWards(selectedWards)
                                                }
                                            }
                                        />
                                    </Form.Group>
                                    </Card.Body>
                                </Card>

                                <div style={{"padding": "10px"}}/>
                                <Card>

                                    <Card.Header> <strong> Step 2: Select Route-Finding Strategy </strong> </Card.Header>
                                    <Card.Body>
                               <Select
                                        options={solverOptions}
                                    value={selectedSolver}
                                        onChange={(selectedOption: OnChangeValue<OptionType, false>) => {
                                            setSelectedSolver(selectedOption ? (selectedOption as SolverOption) : solverOptions[0])
                                        }}/>

                                        <Form.Check
                                            type="switch"
                                            id="stairs-switch"
                                            label="Use stairs"
                                            checked={allowStairs}
                                            className="mt-2"
                                            onChange={() => {
                                            changeMapFiltering(fullMap, !allowStairs)
                                            setAllowStairs(!allowStairs)
                                            }}
                                        />

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={RenderTooltip}
                                        trigger={disableButtons ? ['focus', 'hover'] : []}
                                    >
                                        <span className="d-inline-block">
                                            <Button
                                                variant="primary"
                                                onClick={async () => handleSubmitFindPath(selectedSolver.value)}
                                                className="mt-2"
                                                disabled={disableButtons}
                                            >
                                                Solve!
                                            </Button>
                                        </span>
                                    </OverlayTrigger>
                                    </Card.Body>
                                </Card>
                                </Form>
                            </div>
                        </LoadingOverlay>

                        {route.length > 0 && (
                            <Alert variant="success" className="mt-4">
                                <Alert.Heading>Found a route in {prettyMilliseconds(executionTime)}</Alert.Heading>
                                    <span className="fs-5"><strong>{route.join(' → ')}</strong></span><br/><br/>

                                    <h5>Full route with details: </h5>

                                <ol>
                            {route.slice(0, route.length - 1).map((nodeName, idx) => {
                                        return (
                                            <li>
                                                <strong> {nodeName} </strong>
                                                <details>
                                                    <summary> Landmarks from this location to the next location (click to expand) </summary>

                                                    <div>{
                                                        bestFullPath[idx].slice(1).map((loc, idx) => <span> {String.fromCharCode(idx + 'a'.charCodeAt(0))}. {loc} <br/></span>)
                                                    }</div>
                                                </details>

                                            </li>
                                        )
                                    })}
                                </ol>

                                Debug info: route's cost is {bestCost}
                            </Alert>
                        )}
                        {routeErrors.length > 0 && (
                            <Alert variant="danger" className="mt-4">
                                <Alert.Heading>Routing cancelled. Some wards were not found in the map:</Alert.Heading>
                                <p>{routeErrors.join(', ')}</p>
                            </Alert>
                        )}
                        {uniqueError && (
                            <Alert variant="danger" className="mt-4">
                                <Alert.Heading>You have requested for a route with duplicate wards / locations. </Alert.Heading>
                                <p>This is not supported - please remove any duplicates.</p>
                            </Alert>
                        )}
                        { isSearchingForPath &&
                          <ProgressUI abortSearch={abortSearch} etaMs={etaMs} progressPercent={progressPercent} solveStrategy={solveStrategy}  />
                        }



                        <h2 className="text-center mb-3">Hospital Map</h2>
                        <div className="mt-4 border border-3 rounded p-3">
                            <Graph cyInstance={cyInstance} setCyInstance={setCyInstance} start={startingLocation} end={endingLocation} path={inputWards} map={map} />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer/>
        </LoadingOverlay>
    );
};

export default App;
