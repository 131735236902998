import { Container, Row, Col, Form, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import prettyMilliseconds from 'pretty-ms';

type Props = {
    solveStrategy: "exact" | "genetic",
    etaMs: number,
    progressPercent: number,
    abortSearch: () => void
}

// React functional component for cytoscape graph
const ProgressUI = ({ solveStrategy, etaMs, progressPercent, abortSearch }: Props) => {

    return (
        <div className="mt4 p-3">

            <Alert variant="info" className="mt-4">
                <Alert.Heading>Searching for { solveStrategy === "exact" ? "best" : ""} path... { solveStrategy === "exact" ? "[Note: trying every single possibility, may be slow]" : ""}</Alert.Heading>
                <h5> Time remaining: {prettyMilliseconds(etaMs, {verbose: true})} </h5>
                <ProgressBar animated now={progressPercent} label={`${progressPercent}%`} style={{height: "32px"}} />
                <Button
                    variant="danger"
                    onClick={async () => abortSearch()}
                    className="mt-2 mr-2"
                >
                    Abort Pathfinding
                </Button>
            </Alert>
        </div>
    )

}

export default ProgressUI;
