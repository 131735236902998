import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
//@ts-ignore
import CytoscapeComponent from 'react-cytoscapejs';
import cytoscape from "cytoscape";
import { buildSingleNodeSelector, buildNodeSelector } from '../modules/map_utils'


//@ts-ignore
import fcose from 'cytoscape-fcose';
cytoscape.use(fcose);



type Props = {
    cyInstance: cytoscape.Core | null,
    setCyInstance: (cy: cytoscape.Core) => void,
    map: any,
    start: string,
    end: string,
    path: string[],
}

// React functional component for cytoscape graph
const Graph = ({ cyInstance, setCyInstance, map, start, end, path }: Props) => {
    const [cyGraph, setCyGraph] = useState(<div></div>);

    const computedAlignmentConstraint = (cy: cytoscape.Core | null) => {
        if (cy === null) return {};
        const nodes = cy.nodes();
        const nodeIds = nodes.map((node: cytoscape.NodeSingular) => node.id());
        const wardNodes = nodeIds.filter((id: string) => id.startsWith("W"));
        const groupedNodes: any = {}

        // Group nodes by ward
        wardNodes.forEach((id: string) => {
            const wardId = id.slice(1, 3)
            if (groupedNodes[wardId]) {
                groupedNodes[wardId].push(id)
            } else {
                groupedNodes[wardId] = [id]
            }
        });

        // Iterate through each set of grouped nodes and make them horizontally aligned
        const alignmentConstraint: any = {vertical: [], horizontal: []}
        Object.keys(groupedNodes).forEach((wardId: string) => {
            groupedNodes[wardId].sort();
        });
        Object.keys(groupedNodes).forEach((wardId: string) => {
            const nodes = groupedNodes[wardId];
            alignmentConstraint.horizontal.push(nodes);
        });
        console.log("Alignment constraint: ")
        console.log(alignmentConstraint)

        return alignmentConstraint;
    }

    // Layout
    const fcose = {
        name: "fcose",
        quality: "proof",
        nodeDimensionsIncludeLabels: true,
        nodeRepulsion: (_: any) => 10000,
        edgeElasticity: function (edge: any) {
            // Default is: 10
            // Instead, base it on "weight"
            return (1 / (edge.data("weight") + 1)) * 2
        },
        /* alignmentConstraint: computedAlignmentConstraint(cyInstance), */
        /* idealEdgeDistance: function (edge: any) {
         *   // Default is: 10
         *   // Instead, base it on "weight"
         *     return edge.data("weight") * 0.1
         * },

         */
    }
    const currentLayout = fcose;
    const resetLayout = () => {
        if (cyInstance) {
            cyInstance.layout(currentLayout).run();
        }
    };

    const resetGraph = () => {
        if (cyInstance) {
            cyInstance.reset();
            cyInstance.zoom(0);
            resetLayout();
        }
    };

    const zoomIn = () => {
        if (cyInstance) {
            cyInstance.zoom(cyInstance.zoom() * 1.2);
        }
    };

    const zoomOut = () => {
        if (cyInstance) {
            cyInstance.zoom(cyInstance.zoom() / 1.2);
        }
    };

    const highlightStart = (str: string) => {
        if (cyInstance === null) return;
        cyInstance?.$(`node.starting_node`).removeClass("starting_node")
        cyInstance?.$(buildSingleNodeSelector(str)).addClass("starting_node")
    }

    const highlightEnd = (str: string) => {
        if (cyInstance === null) return;
        cyInstance?.$(`node.ending_node`).removeClass("ending_node")
        cyInstance?.$(buildSingleNodeSelector(str)).addClass("ending_node")
    }

    const highlightPath = (wards: string[]) => {
        if (cyInstance === null || wards.length === 0) return;
        const nodeSelectors = buildNodeSelector(wards);
        cyInstance?.$(`node.path_node`).removeClass("path_node")
        cyInstance?.$(`${nodeSelectors}`).addClass("path_node")
    }

    const styles = [
        {
            selector: 'node[ward]',
            style: {
                /* 'background-color': '#666', */
                'background-color': 'mapData(ward, 43, 78, #a00, #0a0)',
                'label': 'data(id)'
            }
        },
        {
            selector: '.starting_node',
            style: {
                'overlay-color': 'red',
                'overlay-opacity': '0.5',
                "overlay-padding": "5px"
            }
        },
        {
            selector: '.ending_node',
            style: {
                'overlay-color': 'green',
                'overlay-opacity': '0.5',
                "overlay-padding": "5px"
            }
        },
        {
            selector: '.path_node',
            style: {
                'overlay-color': 'blue',
                'overlay-opacity': '0.5',
                "overlay-padding": "5px"
            }
        },
        {
            selector: 'edge',
            style: {
                /* 'width': 3, */
                'width': 'mapData(weight, 0, 10, 3, 10)',
                /* 'line-color': '#ccc', */
                'line-color': 'mapData(weight, 0, 10, #ccc, #f00)',
                'target-arrow-color': '#ccc',
                'target-arrow-shape': 'triangle',
                'curve-style': 'segments',
                'label': 'data(weight)'
            }
        },
        {
            selector: "edge[?isStair]",
            style: {
                /* 'width': 3, */
                'width': '5',
                /* 'line-color': '#ccc', */
                'line-color': 'black',
                'target-arrow-color': '#ccc',
                'target-arrow-shape': 'triangle',
                'curve-style': 'segments',
                'label': 'data(weight)'
            }
        }
    ]

    // We only want to run the layout function on the graph once
    useEffect(() => {
        const cy = <CytoscapeComponent
        elements={map}
        style={{ width: '100%', height: '1000px' }}
        stylesheet={styles}
        layout={currentLayout}
        wheelSensitivity={0.1}
        minZoom={0.1}
        cy={(cy: cytoscape.Core) => {
            // Cytoscape configuration
            setCyInstance(cy);
            highlightStart(start)
            highlightEnd(end)
            highlightPath(path)
        }}/>
        setCyGraph(cy);
    }, [map])

    useEffect(() => {
        highlightStart(start)
        highlightEnd(end)
        highlightPath(path)
    }, [start, end, path])

    return (
        <div>
            <div className="mt-3 d-flex justify-content-between">
                <Button variant="secondary" onClick={resetGraph}>
                    Reset Graph
                </Button>
                <div>
                    <Button variant="secondary" onClick={zoomOut} className="me-2">
                        Zoom Out
                    </Button>
                    <Button variant="secondary" onClick={zoomIn}>
                        Zoom In
                    </Button>
                </div>
            </div>
            <div className="mt-3 d-flex justify-content-center align-items-center">
                <div className="legend-box red-box"></div> Starting point
                <div className="legend-box green-box ms-3"></div> Ending point
                <div className="legend-box blue-box ms-3"></div> Required points to visit
            </div>
            {cyGraph}
        </div>

    );
}

export default Graph;
